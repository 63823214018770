import Arrow from '../static/assets/images/go.svg';
import { Integration } from '../models/category-api-response.model';
import { Link } from 'react-router-dom';
import { Loading } from '../components/Loading';
import Earth from '../static/assets/images/fixed_earth.png';
import { useIntegrations } from '../hooks/useIntegrations';
import './Integrations.css';

function Integrations() {
   const { loading, category, categories, setCategory, featuredIntegrations, additionalIntegrations } = useIntegrations();

   return loading ? (
      <Loading />
   ) : (
      <>
         <section>
            <div className="integrations-container container">
               <div className="head">
                  <h1>integrations</h1>
                  <ul>
                     {categories &&
                        categories.map((item: any) => (
                           <li>
                              <a key={item.id} onClick={() => setCategory(item.attributes.name)} className={category === item.attributes.name ? 'head-li-selected' : ''}>
                                 {item.attributes.name}
                              </a>
                           </li>
                        ))}
                  </ul>
               </div>

               <div className="grid mob-box">
                  {featuredIntegrations &&
                     featuredIntegrations.map((integration: Integration) => (
                        <Link to={integration.attributes.url} target="_blank">
                           <div className="grid-container" key={integration.id}>
                              {integration.attributes.imageUrl != null && <img src={integration.attributes.imageUrl} alt="logo" width="50px" height="50px" />}
                              <div>
                                 <h2>{integration.attributes.name}</h2>
                                 <p>{integration.attributes.description}</p>
                              </div>
                           </div>
                        </Link>
                     ))}
               </div>

               <div className="grid second mob-box">
                  {additionalIntegrations &&
                     additionalIntegrations.map((integration: Integration) => (
                        <Link to={integration.attributes.url} target="_blank">
                           <div className="grid-container" key={integration.id}>
                              <div>
                                 <h2>{integration.attributes.name}</h2>
                                 <p>{integration.attributes.description}</p>
                              </div>
                              <img width="35px" height="35px" src={Arrow} alt="arrow" />
                           </div>
                        </Link>
                     ))}
               </div>
            </div>
         </section>

         <section>
            <div className="bottom">
               <h1>With over 100+ integrations and counting, BONK has asserted its place as one of the premier community coins in web3.</h1>
               <img src={Earth} alt="earth" />
            </div>
         </section>
      </>
   );
}

export default Integrations;
