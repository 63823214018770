import { useState } from 'react';
import './About.css';
import x from '../static/assets/images/x-symbol.png';
import logo from '../static/assets/images/threadless-BONK-new@2x.png';
import Social from '../components/Social';
import { PieChart } from '../components/AboutPie';
import useIsMobile from '../utils/mobile';

function About() {
   const elementStyleArray = Array(100).fill(true);
   const [elementStyle, setElementStyle] = useState(elementStyleArray);

   function setElement(id: number, state: boolean) {
      //change state of specific element in array list
      const updatedArray = [...elementStyle];
      updatedArray[id] = state;
      setElementStyle(updatedArray);
   }

   return (
      <section>
         <div className="about-container container">
            <div className="video">
               {' '}
               {/* video */}
               <h1>a brief history</h1>
                 
               <div>
                  <iframe src="https://www.youtube.com/embed/l-G5tn6HRco"></iframe>
               </div>
            </div>

            <div className="expand-container">
               {' '}
               {/* container for ONE expand menu */}
               <div className="big">
                  {' '}
                  {/* 60% div */}
                  <div onClick={() => setElement(0, !elementStyle[0])}>
                     <h2>utility</h2>
                     <button className={elementStyle[0] ? '' : 'rotate'}>
                        <img src={x} width="30px" />
                     </button>
                  </div>
                  <div className={elementStyle[0] ? 'expand' : ''}>
                     <p>
                        As the true community coin of web3, $BONK's utility comes in the form of its strong community and thriving ecosystem of integrations. To-date $BONK boasts over 120 integrations across 9 separate chains, its adoption continues to grow rapidly having reached over 400,000
                        holders and listings across 41 different centralized and decentralized exchanges.
                     </p>
                  </div>
                  <div className={elementStyle[0] ? '' : 'expand-utility'}>
                     <p>
                        While $BONK's growth to date has been admirable, this promises to be just the beginning for the $BONK community. Concentrated efforts to expand the ecosystem are ongoing and focus on three core pillars each selected to help $BONK in its mission to become the premier community
                        coin in web3.
                        <br />
                        <br />
                        These pillars are:
                        <br />
                        <br />
                        MULTI-CHAIN
                        <br />
                        $BONK was not designed to be enjoyed by only one chain, therefore continuous efforts are in place to allow holders to buy, bridge, use and enjoy their $BONK regardless of their chain of choice!
                        <br />
                        <br />
                        DEFI
                        <br />
                        $BONK strives to simplify DeFi for holders and in doing so help them grow their $BONK holdings.
                        <br />
                        <br />
                        GAMING
                        <br />
                        With over 25 active integrations, $BONK continues to expand its adoption as a preferred in-game currency across blockchain gaming.
                     </p>
                  </div>
               </div>
               <div className="small">
                  {' '}
                  {/* 40% div */}
                  {/* empty -- add picture maby? */}
               </div>
            </div>

            <div className="expand-container">
               {' '}
               {/* container for ONE expand menu */}
               <div className="small">
                  {' '}
                  {/* 40% div */}
                  <img src={logo} width="300px" />
               </div>
               <div className="big">
                  {' '}
                  {/* 60% div */}
                  <div onClick={() => setElement(1, !elementStyle[1])}>
                     <h2>distribution</h2>
                     <button className={elementStyle[1] ? '' : 'rotate'}>
                        <img src={x} width="30px" />
                     </button>
                  </div>
                  <div className={elementStyle[1] ? 'expand' : ''}>
                     <p>$BONK’s distribution strategy is completely centered around giving back to the community and has primarily focused on two core objectives. The first objective was to reward the downtrodden Solana community after an extremely difficult year in 2022.</p>
                  </div>
                  <div className={elementStyle[1] ? '' : 'expand-distribution'}>
                     <p>
                        At the end of 2022, Solana needed some fun and excitement to be injected back into the network, and what’s more fun than a free community airdrop?
                        <br />
                        <br />
                        On Christmas Day 2022, 50% of the $BONK supply was airdropped to all the community members that make Solana the thriving community it is today. From NFT degens and DeFi enjoyers to giga brain developers and promising 1/1 artists, everyone in the community woke up on Christmas
                        day to the arrival of this fun community coin in their wallets.
                        <br />
                        <br />
                        In addition to successfully revitalising the Solana community, BONK’s second objective with its distribution strategy has been to align incentives and ensure that the BONK ecosystem continues to thrive well into the future.
                        <br />
                        <br />
                        To achieve this objective, a significant amount of tokens have been earmarked for the BONKDAO to allow community members to form and fund initiatives that light their fire as well as for marketing endeavours, development activities, grants and other activities that further
                        drive BONK’s growth.
                        <br />
                        <br />
                        Finally, propelling the BONK vision forward are its early contributors, each dedicated to the long-term success of web3’s true community coin. To prove their loyalty this thriving ecosystem, each contributor is subject to 3 year-long linear vesting schedules ensuring all
                        incentives are aligned for BONK’s long-term success.
                     </p>
                  </div>
               </div>
            </div>

            <div className="info-grid mob-box">
               <div>
                  <div onClick={() => setElement(2, !elementStyle[2])}>
                     <h3>40 solana nft projects</h3>
                     <button className={elementStyle[2] ? '' : 'rotate'}>
                        <img src={x} width="15px" />
                     </button>
                  </div>
                  <div className={elementStyle[2] ? '' : 'a'}>
                     <p>
                        21% of the coin is being distributed amongst 40 active Solana NFT Projects, representing more than 296,000 individual NFTs. These are spread between high, middle and low cap collections, to prevent the potential accrual of all tokens towards owners of expensive NFTs. This
                        will be based on a snapshot of delisted NFTs, and airdropped via the Famous Fox Federation Tools.
                     </p>
                  </div>
               </div>
               <div>
                  <div onClick={() => setElement(3, !elementStyle[3])}>
                     <h3>supply distribution</h3>
                     <button className={elementStyle[3] ? '' : 'rotate'}>
                        <img src={x} width="15px" />
                     </button>
                  </div>
                  <div className={elementStyle[3] ? '' : 'b'}>
                     <p>15.8% of the Supply is going towards Solana market participants and DeFi users.</p>
                  </div>
               </div>
               <div>
                  <div onClick={() => setElement(4, !elementStyle[4])}>
                     <h3>artists/collectors</h3>
                     <button className={elementStyle[4] ? '' : 'rotate'}>
                        <img src={x} width="15px" />
                     </button>
                  </div>
                  <div className={elementStyle[4] ? '' : 'c'}>
                     <p>10.5% of the Supply is going towards the Artists and Collectors of 1/1 art, using a snapshot of activity from the Collector.sh team. This will not be proportional to volume traded, but there will be a minimal cutoff.</p>
                  </div>
               </div>
               <div>
                  <div onClick={() => setElement(5, !elementStyle[5])}>
                     <h3>solana developers</h3>
                     <button className={elementStyle[5] ? '' : 'rotate'}>
                        <img src={x} width="15px" />
                     </button>
                  </div>
                  <div className={elementStyle[5] ? '' : 'd'}>
                     <p>
                        5.3% of the Supply is going towards Developers on the Solana Chain. It's vital to continue rewarding those who build the programs we need to create things like BONK, and will hopefully be the first of many airdrops towards Solana Developers going forward. This is being
                        facilitated by LamportDAO. BONK is a community coin which may be integrated into Solana dApps across the entire network and serve as a unit of account and user participation reward.
                     </p>
                  </div>
               </div>
               <div>
                  <div onClick={() => setElement(6, !elementStyle[6])}>
                     <h3>early contributors</h3>
                     <button className={elementStyle[6] ? '' : 'rotate'}>
                        <img src={x} width="15px" />
                     </button>
                  </div>
                  <div className={elementStyle[6] ? '' : 'e'}>
                     <p>
                        21% is allocated to the early contributors who helped launch, and continue to advance BONK since its inception. There are 22 individuals included in this allocation, each of whom has provided early support in the form of artwork, token design, marketing, integrations, web
                        development, business development, and provision of early liquidity. Each individual remains dedicated to driving Bonk’s long-term success, and this will be reflected with a 3-year linear token vesting period beginning from January 1st, 2023.
                     </p>
                  </div>
               </div>
               <div>
                  <div onClick={() => setElement(7, !elementStyle[7])}>
                     <h3>bonkdao</h3>
                     <button className={elementStyle[7] ? '' : 'rotate'}>
                        <img src={x} width="15px" />
                     </button>
                  </div>
                  <div className={elementStyle[7] ? '' : 'f'}>
                     <p>
                        15.8% will go towards a BONK DAO to be used for initiatives conceived by the wider BONK community. A Realms Instance will be established that will then control further Distribution and allocation of BONK for these initiatives. Program authority over the Token will be
                        delegated to the Realms Instance and guided by token holders going forward.
                     </p>
                  </div>
               </div>
               <div>
                  <div onClick={() => setElement(8, !elementStyle[8])}>
                     <h3>initial liquidity</h3>
                     <button className={elementStyle[8] ? '' : 'rotate'}>
                        <img src={x} width="15px" />
                     </button>
                  </div>
                  <div className={elementStyle[8] ? '' : 'g'}>
                     <p>5.3% has been allocated towards Initial Liquidity Distribution, this is being used in Raydium, Orca, and Solend, alongside other platforms like FFFlip, Monaco Protocol, and the BoiBook. Any rewards earned via this distribution will be returned to the BONKDAO.</p>
                  </div>
               </div>
               <div>
                  <div onClick={() => setElement(9, !elementStyle[9])}>
                     <h3>marketing</h3>
                     <button className={elementStyle[9] ? '' : 'rotate'}>
                        <img src={x} width="15px" />
                     </button>
                  </div>
                  <div className={elementStyle[9] ? '' : 'h'}>
                     <p>5.3% will be used for marketing promotions with teams like AssetDash, and further giveaways and incentives for the promotion of BONK.</p>
                  </div>
               </div>
            </div>
            {useIsMobile() ? (
               <PieChart />
            ) : (
               <div className="timeline">
                  <div className="a">
                     <div />
                     <p>
                        21%
                        <br />
                        sol nft projects
                     </p>
                  </div>
                  <div className="e">
                     <div />
                     <p>
                        21%
                        <br />
                        early contributors
                     </p>
                  </div>
                  <div className="b">
                     <div />
                     <p>
                        15.8%
                        <br />
                        market participants and defi users
                     </p>
                  </div>
                  <div className="f">
                     <div />
                     <p>
                        15.8%
                        <br />
                        bonk dao
                     </p>
                  </div>
                  <div className="c">
                     <div />
                     <p>
                        10.5%
                        <br />
                        artists & collectors
                     </p>
                  </div>
                  <div className="d">
                     <div />
                     <p>
                        5.3%
                        <br />
                        solana devs
                     </p>
                  </div>
                  <div className="g">
                     <div />
                     <p>
                        5.3%
                        <br />
                        initial liquidity
                     </p>
                  </div>
                  <div className="h">
                     <div />
                     <p>
                        5.3%
                        <br />
                        marketing
                     </p>
                  </div>
               </div>
            )}
         </div>
      </section>
   );
}

export default About;
