import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Home.css';
import '../static/style/mobile.css';
import { Card } from '../components/Card';
import { HomeHeader } from '../components/HomeHeader';
import arrow from '../static/assets/images/Icon feather-chevron-down.svg';
import bloomberg from '../static/assets/images/bloomberg@2x.png';
import benzinga from '../static/assets/images/benzinga.svg';
import cryptonews from '../static/assets/images/cryptonews.svg';
import coindesk from '../static/assets/images/coindesk@2x.png';
import x from '../static/assets/images/xlogo.png';
import { CategoryApiResponseModel } from '../models/category-api-response.model';
import { fetchBonkDataIntegrations } from '../services/strapi.service';
import { getGetBonk, getNumIntegrations, getUseBonk } from '../utils/handleAPIData';
import { Loading } from '../components/Loading';
import keyProjects from '../static/data/keyprojects.json';

function Home() {
   const [loading, setLoading] = useState<boolean>(false);
   const [num_integrations, setNumIntegrations] = React.useState(0);
   const [data, setData] = useState<CategoryApiResponseModel | null>(null);
   const [error, setError] = useState<Error | null>(null);
   const navigate = useNavigate();

   useEffect(() => {
      setLoading(true);
      async function fetchData() {
         const data = await fetchBonkDataIntegrations();
         if (error) {
            setError(error);
         } else {
            setData(data);

            setNumIntegrations(getNumIntegrations(data));
         }
      }
      fetchData();
      setLoading(false);
   }, []);

   const useBonkResultList = getUseBonk(data);
   const useBonkDesc = `${num_integrations} INTEGRATIONS & COUNTING` //use bonk description

   const getBonkResultList = getGetBonk(data);

   return loading ? (
      <Loading />
   ) : (
      <div className="flex column">
         <HomeHeader />
         <section className="section bg-orange-secondary-gradient">
            <div className="container flex column gap">
               <Card className="mission bg-yellow-gradient" title="ABOUT BONK" 
               description="BONK IS THE SOCIAL LAYER AND COMMUNITY MEME COIN OF SOLANA WITH DEEP INTEGRATIONS AS A UTILITY TOKEN 
               ACROSS A WIDE BASE OF APPLICATIONS AND PROTOCOLS WITHIN THE WEB3 ECOSYSTEM.  WE AIM TO ACHIEVE THIS THROUGH EXPANDING OUR GROWING LIST OF INTEGRATIONS ACROSS:">
                  <div className="flex gap our-mission">
                     <div className="multi-links btn">
                        <div>
                           <div />
                           <h3>MULTI-CHAIN</h3>
                           <img src={arrow} alt="arrow" />
                        </div>
                        <div className="menu flex column gap-small">
                           <Link to="https://portalbridge.com/#/transfer" target="_blank">
                              WORMHOLE
                           </Link>
                           <Link to="https://buybonk.com/" target="_blank">
                              BUY BONK
                           </Link>
                        </div>
                     </div>

                     <Link to={`/integrations?category=DeFi`} className="btn primary">
                        DEFI
                     </Link>
                     <Link to={`/integrations?category=Gaming`} className="btn primary">
                        GAMING
                     </Link>
                  </div>
               </Card>
               <div className="flex between gap mob-box">
                  <Card className="w-100 bg-yellow-gradient" title="USE BONK" description={useBonkDesc}>
                     <div className="usebonk">
                        <div className="mob-box">
                           {useBonkResultList.map((entry, index) => (
                              <div onClick={() => navigate(`/integrations?category=${entry[0]}`)} key={index} className={index >= useBonkResultList.length - 2 ? "no-border" : ""}>
                                 <p>{entry[1]}</p>
                                 <p>{entry[0]}</p>
                              </div>
                           ))}
                        </div>
                     </div>
                  </Card>
                  <Card className="getbonk w-100 bg-orange-gradient" title="GET BONK" description="40+ DEXS & CEXES, BUY BONK TODAY">
                     <div className="mob-box">
                        {getBonkResultList.map((entry, index) => (
                           <Link to={entry[2]}>
                              <div key={index}>
                                 <img src={entry[1]} alt="getbonk img" />
                                 <p>{entry[0]}</p>
                              </div>
                           </Link>
                        ))}
                     </div>
                     <Link to={`/integrations?category=Exchange`}>
                        <div className="flex h-100 center items-end">
                           <img src={arrow} alt="arrow" />
                        </div>
                     </Link>
                  </Card>
               </div>
               <div className="keyprojects flex gap flex-col">
                  <h1 className="ml-3">key projects</h1>
                  <div className="grid-container">

                     {keyProjects.map((project, index) => (
                        <Link to={project.url} key={index} className="flex w-100">
                           <Card className={project.bgClass}>
                              <img src={project.imageLg} alt={project.name} height="100px"  />
                              <h3>{project.name}</h3>
                              <p>{project.caption}</p>
                           </Card>
                        </Link>
                     ))}
                  </div>
               </div>

            </div>
         </section>

         
      </div>
   );
}

export default Home;
