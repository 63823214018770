import './Footer.css';
import { Link } from 'react-router-dom';
import { ReactComponent as Spid3r } from '../static/assets/svg/spid3r-logo.svg';

function Footer() {
   const currentYear = new Date().getFullYear();

   return (
      <footer className="footer flex column items-center gap-large top-border">
         <div className="flex center wrap footer-gap">
            <div className="flex column gap">
               <Link to="/">HOME</Link>
               <Link to="/keyprojects">KEY PROJECTS</Link>
               {/* <Link to="/integrations">INTEGRATIONS</Link> */}
               <Link to="/bonkart">ART</Link>
               {/* <Link to="/nft">NFT</Link> */}
               <Link to="/about">ABOUT</Link>
               {/* <Link to="/">BRANDING</Link> */}
               
            </div>
            <div className="flex column gap">
               <h1>INTEGRATIONS</h1>
               <div className="flex between">
                  <div className="flex column gap">
                     <Link className="secondary" to="/integrations">
                        FEATURED
                     </Link>
                     <Link className="secondary" to="/integrations?category=Defi">
                        DEFI
                     </Link>
                     <Link className="secondary" to="/integrations?category=Developer">
                        DEVELOPER
                     </Link>
                     <Link className="secondary" to="/integrations?category=Exchange" target='_blank'>
                        EXCHANGE 
                     </Link>
                     <Link className="secondary" to="/integrations?category=Gaming">
                        GAMING
                     </Link>
                  </div>
                  <div className="flex column gap">
                     {/* <Link className="secondary" to="/integrations?category=Nft">
                        NFT
                     </Link> */}
                     <Link className="secondary" to="/integrations?category=Transfer%20Tools">
                        TRANSFER TOOLS
                     </Link>
                     <Link className="secondary" to="/integrations?category=Social">
                        SOCIAL
                     </Link>
                     <Link className="secondary" to="/integrations?category=Wallet">
                        WALLET
                     </Link>
                  </div>
               </div>
            </div>
            <div className="flex column gap">
               {/* <h1>MORE</h1> */}
               <div className="flex column gap">
               <Link target="_blank" to="https://bonk.sfo3.cdn.digitaloceanspaces.com/docs/BONK-Paper.pdf">WHITE PAPER</Link>
               <Link target="_blank" to="https://bonkcoin.myshopify.com/">MERCH</Link>
                  {/* <Link className="secondary" to="https://www.bonkbot.io" target="_blank">
                     BONK BOT
                  </Link>
                  <Link className="secondary" to="https://www.bonkswap.io" target="_blank">
                     BONK SWAP
                  </Link>
                  <Link className="secondary" to="https://moonwalk.io" target="_blank">
                     MOONWALK
                  </Link>
                  <Link className="secondary" to="https://buybonk.com/" target="_blank">
                     BUY BONK
                  </Link> */}
                  {/* <Link className="secondary" to="https://openbonk.io" target="_blank">
                     OPENBONK
                  </Link> */}
               </div>
            </div>
         </div>
         <p className="small white">All Rights Reserved © {currentYear} Bonk Inu</p>
         <a href="https://spid3r.dev" target='_blank'><Spid3r className="spid3r" width="20px" /></a>
      </footer>
   );
}

export default Footer;
